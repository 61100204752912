@import url("https://fonts.googleapis.com/css?family=Raleway:400,700,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
  white-space: pre-line;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  h1 {
    @apply text-3xl md:text-4xl mb-4 md:mb-10;
  }
  h2 {
    @apply text-xl mb-2;
  }
  h3 {
    @apply uppercase mb-2 text-xs;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span a {
  text-decoration: underline;
}

.verbose {
  font-family: monospace;
  color: #800000;
}
